@import 'src/assets/variables.scss';

.globeLoaderHeader{
    display: grid;
    grid-template-areas: 'logo skeletonMain';
    grid-template-columns: 1fr 4fr;
    grid-row-gap: 0.7rem;
    grid-column-gap: 1.5rem;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    
    .logo {
        grid-area: logo;
        min-width: 20.6rem;
        height: 4.4rem;
        background-image: url(~assets/images/travel_stride_logo.svg);
        background-size: contain;
        background-repeat: no-repeat;
        @media (max-width: 1290px) {
            display: block;
            margin-bottom: 15px;
        }
    }
    @media (max-width: 1290px) {
        display: block;
    }
    .skeletonMain{
        grid-area: skeletonMain;
        > div{
            display: grid;
            grid-template-areas: 'bigSkeleton shortSkeleton blankspace veryShortSkeleton veryShortSkeleton veryShortSkeleton';
            grid-template-columns: 4fr 1fr 0.5fr 1fr 1fr 1fr;
            grid-row-gap: 1rem;
            grid-column-gap: 1.5rem;
            justify-content: space-between;
            align-items: center;
            .bigSkeleton{
                grid-area: bigSkeleton;
            }
            .blankspace{
                grid-area: blankspace;
            }
            .shortSkeleton{
                grid-area: shortSkeleton;
            }
            .veryShortSkeleton{
                grid-area: veryShortSkeleton;
            }
            @media (max-width: 1290px) {
                grid-template-areas: 'bigSkeleton';
                grid-template-columns: 1fr;
                grid-row-gap: 0;
                grid-column-gap: 0;
                .blankspace,
                .shortSkeleton,
                .veryShortSkeleton{
                    display: none;
                }
            }
        }
    }
    @media (max-width: 1290px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}
