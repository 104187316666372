@import "assets/variables.scss";

.root {
  display: flex;
  bottom: 0;
  width: 50%;
  margin: 2rem;
  @media (max-width: $less-than-medium) {
    width: 90%;
    margin-top: 1rem;
  }

  .previous {
    display: flex;
    align-items: center;
    color: var(--Stride-Medium-Gray, #888);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 4px;
    border: 2px solid var(--Stride-Medium-Gray, #888);
    background-color: #fff;
    svg {
      margin-right: 8px;
    }

    a {
      text-decoration: none;
      color: #888888;
    }
  }

  .next {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 4px;
    background: var(--Stride-Light-Gray, #979797);
  }

  .nextActive {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 4px;
  }
}

.placeholder {
  flex: 1;
}
