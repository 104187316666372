@import "assets/variables.scss";

.root {
  display: block;
}

.label {
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
  white-space: break-spaces;
  font-weight: 500;
  font-size: 16px;

  .checkboxCustom {
    border-radius: 2px;
    border: 1px solid #dadada;
    background-color: transparent;
    width: 22px;
    height: 22px;
    margin-right: 12px;
    position: relative;
    flex-shrink: 0;
  }

  &:hover {
    //color: $stride-color-dark-gray;

    .checkboxCustom {
      border-color: $grey-light;
    }
  }

  &.labelChecked {
    color: black;
    font-weight: 500;

    .checkboxCustom {
      background-color: $color-primary;
      border: 1px solid $color-primary;
      flex-shrink: 0;
      //box-shadow: 0 1px 0 0 $stride-color-border-gray;

      &:after {
        display: block;
        flex-shrink: 0;
      }
    }
  }

  & .checkboxCustom:after {
    position: absolute;
    left: calc(50% - 4.5px);
    top: calc(50% - 5px);
    content: '';
    width: 9px;
    height: 5px;
    transform: rotate(-45deg);
    border: solid white;
    border-width: 0 0 2px 2px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    display: none;
  }
}

.default {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  flex-shrink: 0;
}
