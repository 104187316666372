@import 'assets/variables.scss';

.root {
  position: relative;
  height: 150px;
  min-width: 120px;
  background: white;
  border-radius: 3px;
  border: 1px solid #DADADA;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow .3s;
  outline: none;

  &.selected {
    border: 2px solid #21AC5D;
  }
}


.check {
  //width: 1.5rem;
  //height: 1.5rem;
  //border-radius: 50%;
  //position: absolute;
  //z-index: 3;
  //right: .625rem;
  //top: .625rem;
  //background-position: 50%;
  //background-color: rgba(255, 255, 255, .05);
  //transition: background-color .3s;
  //
  //&.checked {
  //  background-image: url(~assets/images/check.svg);
  //  background-color: rgba(255, 255, 255, .5);
  //}
}

