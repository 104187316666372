@import 'src/assets/variables.scss';

.globeLoader{
  display: none;
  position: fixed;
	height: 100%;
	width: 100%;
	//background: $stride-color-background-gray;
  background: $stride-color-white;
	top: 0;
	left: 0;
	z-index: 19;
    &.activeLoader{
        display: block;
    }
    .globeLoaderContainer{
      position: absolute;
      top: 40%;
      left: 0;
      right: 0;
    }
    .globeIcon{
        background-image: url('~assets/images/world-map.png');
        background-repeat: repeat-x;
        margin: 0 auto;
        background-color: $stride-color-white;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        overflow: hidden;
        animation: slide 2.5s linear infinite;
        -webkit-animation:slide 2.5s linear infinite;
        border:2px solid $stride-color-border-gray;
    }
}
@keyframes slide {
    0% {
      background-position: 0%;
  
    }
    100% {
      background-position: 240px;
    }
  }
  @-webkit-keyframes slide {
    0% {
      background-position: 0%;
  
    }
    100% {
      background-position: 240px;
    }
  }