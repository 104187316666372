@import "assets/variables.scss";

.CalendarDay__selected {
  background: $brand-green;
  color: white;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  background: lighten($brand-green, 5%);
  color: white;
}

.CalendarDay {
  outline: none;
}

.DateInput {
  width: 124px;
}

.inputTitle {
  color: var(--Neutral-600, #6f6c90);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}
