// Colors
$grey40: #666666;
$grey60: #999999;
$grey-light: #dddddd;
$grey90: #e5e5e5;
$grey92: #ebebeb;
$grey96: #f5f5f5;

$brand-green: #1bb178;

// Fonts
$font-size-tiny: 12px;

// Breakpoints
$less-than-medium: 768px;
$less-than-small: 480px;

// stride-core-ui
$color-primary: #177a68;
$color-primary-darken: var(--primary-color-darken);
$color-secondary: #999999;
$color-danger: #d73a49;

$button-height-medium: 3.125rem;
$button-width-medium: 10rem;
$button-height-small: 2.5rem;
$button-width-small: 6.56rem;

$border-radius-regular: 6px;

$input-height-medium: 3.125rem;
$input-border-color: #dddddd;

$plugin-size-medium: 1rem;

$font-size-regular: 1rem;
$font-size-small: 0.875rem;

$stride-color-green: #21ac5d;
$stride-color-gradient: #0b8f44;
$stride-color-light-green: #edf9f3;
$stride-color-yellow: #ffca00;
$stride-color-orange: #fabd22;
$stride-color-gold: #d29d00;
$stride-color-red: #ff0000;
$stride-color-silver: #b7bac3;
$stride-color-blue: #4ebe9d;
$stride-color-dark-blue: #00a2d6;

$stride-color-black: #333333;
$stride-color-dark-gray: #666666;
$stride-color-medium-gray: #888888;
$stride-color-light-gray: #979797;
$stride-color-border-gray: #dadada;
$stride-color-background-gray: #f8f7fc;
$stride-color-background-green: #e6faee;
$stride-color-white: #ffffff;
$font-weight-normal: 400;

$gradient-primary: var(--primary-gradient);
