@import "assets/variables";

.container {
  display: flex;
  flex-direction: row;
  gap: 0;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100vh;
  background-size: 100% 105% !important;
  background: url(~assets/images/chat-version/background.jpg);
  &.receiptMobile {
    background: none;
  }
  @media (max-width: 1025px) {
    background: url(~assets/images/chat-version/mobile-background.jpg);
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    width: 50%;
    background: var(--www-rome-2-rio-com-nero, #fff);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem 2rem;
    height: 80px;
    position: fixed;
    top: 0;
    transition: all 0.3s ease-in-out;
    @media (max-width: 1025px) {
      width: 100%;
      background-color: transparent;
      background: linear-gradient(
        180deg,
        #fff 0%,
        rgba(255, 255, 255, 0) 89.72%
      );
      box-shadow: none;
    }
    &.intro {
      width: 100% !important;
    }
    .logo {
      width: 158px;
      height: 40px;
      div {
        width: max-content;
        color: #333;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 0.2rem;
        span {
          color: #177a68;
          text-align: right;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
  .chatContainer {
    overflow-y: auto;
    padding-right: 1rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100vh;
    background: linear-gradient(0deg, #177a68 10.96%, #f4f4f4 108.35%);
    transition: all 0.3s ease-in-out;
    @media (max-width: 1025px) {
      object-fit: cover;
      padding: 0 !important;
      overflow: auto;
      min-height: 100vh;
      width: 100%;
    }
    &.receiptMobile {
      display: none;
      background: none;
    }
    &.intro {
      background: transparent;
    }
    .titleDiv {
      display: flex;
      gap: 1rem;
      padding: 1rem 0;
      padding-bottom: 0px;
      @media (max-width: 1025px) {
        height: auto;
        flex-direction: column;
      }
      .descriptionDiv {
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        gap: 1rem;
        @media (max-width: 1025px) {
          margin-left: 0;
        }
        .how {
          color: var(--CTA-secondary-button-border, #177a68);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .btn {
          margin-left: auto;
          color: #fff;
          display: flex;
          border-radius: 4px;
          background: var(--Stride-Border-Gray, #dadada);
          justify-content: center;
          align-items: center;
          width: 128px;
          height: 40px;
          gap: 1rem;
          div {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          span {
            font-size: 9px;
            color: #fff;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          &.active {
            background: var(--CTA-primary-button-border, #177a68);
          }
        }
      }
      .title {
        display: flex;
        flex-direction: column;
        gap: 5px;
        h2 {
          color: #1f1e1e;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 27px; /* 84.375% */
        }
        span {
          color: var(--Stride-Light-Gray, #979797);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
  .rightSide {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    background-size: contain;
    transition: all 0.3s ease-in-out;
    @media (max-width: 1025px) {
      display: none;
    }
    &.receiptMobile {
      background: none !important;
      display: flex;
      width: 100%;
    }
    .map {
      height: 50%;
      width: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      background-size: 101% 105%;
      @media (max-width: 1025px) {
        display: none;
        background-color: #fff;
      }
      .actionDiv {
        display: flex;
        height: 80px;
        width: 100%;
        padding: 2rem 0rem;
      }
      .mapContainer {
        width: 100%;
        height: 90vh;
        border-radius: 10px;
      }
    }
    .summary {
      transition: all 0.3s ease-in-out;
      gap: 1rem;
      height: 50%;
      background-size: 100vw;
      object-fit: cover;
      display: flex;
      flex-direction: column;
      overflow: auto;

      @media (max-width: 1025px) {
        margin-top: 20%;
        background: none !important;
      }
    }
  }
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  background: rgba(49, 49, 49, 0.8);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}
.modalContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  @media (max-width: 1025px) {
    width: 90%;
  }
  .titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
    .how {
      color: var(--CTA-secondary-button-border, #177a68);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .btnNoSave {
      border-radius: 4px;
      padding: 1rem 0.5rem;
      border: 2px solid var(--CTA-secondary-button-border, #177a68);
      color: var(--CTA-secondary-button-border, #177a68);
      background-color: #fff;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .btnSave {
      border-radius: 4px;
      background: var(--CTA-secondary-button-border, #177a68);
      padding: 1rem 0.5rem;
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    h2 {
      color: #000;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .close {
      font-size: 1.5rem;
    }
  }
  .steps {
    h4 {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    span {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .btn {
      margin-top: 1rem;
      margin-right: auto;
      color: #fff;
      display: flex;
      border-radius: 4px;
      background: var(--Stride-Border-Gray, #dadada);
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 40px;
      gap: 1rem;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      span {
        font-size: 9px;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      &.active {
        background: var(--CTA-primary-button-border, #177a68);
      }
    }
  }
  p {
    color: var(--color-text-neutral-base, #1d1f23);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-bottom: 2rem;
  }
}

.closeModal {
  margin-top: 1rem;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 4px;
  background: var(--Stride-Black, #333);
  width: 100%;
  height: 56px;
}
