.root {
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  .info {
    width: 1.6rem;
  }

  &:hover {
    .text {
      display: flex;
      flex-direction: row-reverse;
      align-items: baseline;
      position: absolute;
      text-align: initial;
      background: #fff;
      bottom: 100%;
      padding: 1.5rem;
      width: 20rem;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
      border-radius: 0.4rem;
    }
  }
}

.close {
  display: block;
  margin-left: 1rem;
}

.tooltip {
  max-width: 300px;
  &.text {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    padding: 1rem;
    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
