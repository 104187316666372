@import 'src/assets/variables.scss';

.loaderQuotes{
    max-width: 520px;
    margin: 0 auto;
    text-align:center;
    font-size:20px;
    line-height:32px;
    margin-top:30px;
    padding: 0 15px;
    color: $stride-color-medium-gray;
    font-weight: $font-weight-normal; 
    //display:none;
    .author{
        color:#333;
        margin-top:10px;
    }
}