@import "assets/variables.scss";

.root {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background-color, 0.3s;
  color: #fff;
  border-width: 0;
  border-style: solid;
  border-radius: $border-radius-regular;
  fill: #fff;
  font-size: 18px;
  border-radius: var(--radius-md, 6px);
}

button.root {
  -webkit-appearance: none;
}

a.root {
  text-decoration: none;
}

.medium {
  height: $button-height-medium;
  min-width: $button-width-medium;
}

.small {
  height: $button-height-small;
  min-width: $button-width-small;
}

.primary {
  background: $color-primary;
  font-size: 1rem;

  &:hover {
    background-color: $color-primary-darken;
  }

  &.outlined {
    color: $color-primary;
    border-color: $color-primary;
    fill: $color-primary;
  }
}

.secondary {
  background-color: #e1e1e1;
  color: #333333;
  font-size: 1rem;
  font-weight: 600;

  &:hover {
    background-color: darken($color-secondary, 2%);
  }

  &.outlined {
    color: $color-secondary;
    border-color: $color-secondary;
    fill: $color-secondary;
  }
}

.blank {
  background-color: white;
  color: $grey60;

  &:hover {
    background-color: darken(white, 3%);
  }
}

.outlined {
  background-color: transparent;
  color: #177a68;
  border-width: 0;
  font-size: 16px;

  &:hover {
    background-color: #e1e1e1;
  }
  svg {
    margin-right: 0.5rem;
  }
}

.disabled {
  background: #dadada;
  cursor: default;

  &:hover {
    background: #dadada;
  }
}
