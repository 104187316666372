@use "sass:color";
@import-normalize;
@import "assets/variables.scss";

:root {
  --percentage: 10%;
  --opacity: calc(var(--percentage) * 0.01);
  --primary-color-hue: #{color.hue($stride-color-green)};
  --primary-color-saturation: #{color.saturation($stride-color-green)};
  --primary-color-lightness: #{color.lightness($stride-color-green)};
  --primary-gradient-hue: #{color.hue($stride-color-gradient)};
  --primary-gradient-saturation: #{color.saturation($stride-color-gradient)};
  --primary-gradient-lightness: #{color.lightness($stride-color-gradient)};
  --primary-color: hsl(
    var(--primary-color-hue),
    var(--primary-color-saturation),
    var(--primary-color-lightness)
  );
  --primary-color-lighten: hsl(
    var(--primary-color-hue),
    var(--primary-color-saturation),
    calc(var(--primary-color-lightness) + var(--percentage))
  );
  --primary-color-darken: hsl(
    var(--primary-color-hue),
    var(--primary-color-saturation),
    calc(var(--primary-color-lightness) - var(--percentage))
  );
  --primary-gradient: hsl(
    var(--primary-gradient-hue),
    var(--primary-gradient-saturation),
    var(--primary-gradient-lightness)
  );
}

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #333333;
  font-size: 18px;
}

* {
  box-sizing: border-box;
}

button {
  -webkit-appearance: none;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

.messages {
  display: none;
}

a {
  color: black;
  text-decoration-color: #21ac5d;
}
