
.root {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  width: 100%;
}

.title {
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 5px;
  //position: absolute;
  //bottom: .5rem;
  //left: 0.5rem;
  //font-weight: bold;
  //font-size: 1rem;
  //text-align: left;
}
